.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.animalRow{
  
  font-size: 20px;
  display: flex;
  padding: 3px;
  
  border: 1px solid black;
}
.animalImage {
   width: 250px; 
   
}
.animalRow img{
  
  height: 170px;
  
}

.animalID
{
  display: flex;
  flex-direction: column;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .animalRow {
    font-size: 15px;
  }
}

.aacHeader{
  margin: 5px;
}

h1 {
  
  margin: 2px;
}

.smallText{
  
  font-size: 10px;
}